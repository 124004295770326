<template>
  <div>
        <CPagination
          v-if="pages>0"
          align="center"
          :pages="pages"
          :active-page.sync="currentPage_"
        />
  </div>
</template>

<script>
export default {
  name: 'Paginations',
  props: {
    currentRow: {
      type: Number,
      default: 1
    },
    countRows: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 10
    },
  },
  data () {
    return {
      currentPage_: this.currentPage
    }
  },
  computed: {
    pages() {
      return Math.floor(this.countRows/this.perPage);
    }
  },
  watch: {
    currentPage_(val) {
      this.$emit('set-page', val);
    }
  }
}
</script>
