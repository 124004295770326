<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CRow>
          <CCol sm="6">
            <CIcon name="cil-grid"/> {{caption}}
          </CCol>
          <CCol sm="6">
            <div  class="float-right">
              Записей: {{ countRows }}
            </div>
          </CCol>
        </CRow>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        :pagination="true"
      >
        <template #status="{item}">
          <td>
            <CBadge :color="getBadge(item.status)">{{item.status}}</CBadge>
          </td>
        </template>
        <template #exported="{item}">
          <td class="text-center">
            <span v-if="item.exported>0">
              да
            </span>
          </td>
        </template>
        <template #actions="{item}">
          <td>
            <div v-if="item.type!=='испорчен'">
              <CButton @click="showJpg(item)">
                Фото
              </CButton>
              <CButton @click="loadPdf(item)">
                PDF
              </CButton>
            </div>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: 'Table',
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return ['Номер','Дата','Дата создания','Клиент','Тип', 'Примечание','Выгружено в Аршин','Действия']
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    countRows: {
      type: Number,
      default: 0
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
    }
  },
  methods: {
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    showJpg(item) {
      // console.log(item);
      this.$emit('show-document', item);
      this.show_document = true;
    },
    loadPdf(item) {
      window.open(`/data/act/pdf?id=${item.number_act}&pin=${item.pin}`, '_blank')
    }
  }
}
</script>
